import { ExternalIntegrationRepository, ExternalIntegration } from '@warehouse/object-browser/core';
import { singletonFactory } from '@warehouse/shared/util';
import availableIntegrations from '../../../src/assets/json-administration-profiles/available-integrations/available-integrations.json';

export class ExternalIntegrationRepositoryJson implements ExternalIntegrationRepository {
	public getAvailable(): ExternalIntegration[] {
		return availableIntegrations;
	}
}

export const externalIntegrationsRepositorySingleton = singletonFactory<ExternalIntegrationRepository>({
	factory: () => new ExternalIntegrationRepositoryJson(),
});
