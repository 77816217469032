import React, { useCallback, useRef } from 'react';
import { CellContext } from '@tanstack/react-table';
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';

import { ContentIdentifier } from '@warehouse/title/core';
import { TableTextInput } from '../../../../../src/views/title/tab/productMetadata/style';
import useBlurAndDebounceValue from '../../../../../src/views/title/hooks/useTitleRecordsAutoSave/useBlurAndDebounceValue';
import { CellWrapper } from '../../../../../src/components/library/SimpleTable/style';
import availContentIDNS from '../../../../../src/assets/json-administration-profiles/availableContentIDNamespaces.json';

export function CellIdentifier({
	getValue,
	table,
}: CellContext<Inherited<ContentIdentifier>, Inherited<ContentIdentifier>>) {
	const { displayValue } = getValue();
	const editRecord = table.options?.meta?.customPropsCell?.editRecord;

	const matchNamespaceForIdentifier = (v: string) => {
		const matchingIdentifierTypes = availContentIDNS
			.filter((pattern) => pattern.regex && new RegExp(pattern.regex).test(v))
			.map((pattern) => pattern.value);

		const namespace = matchingIdentifierTypes && matchingIdentifierTypes.length === 1 ? matchingIdentifierTypes[0] : '';
		const newNamespace = displayValue.namespace.length > 1 ? displayValue.namespace : namespace;
		return newNamespace;
	};

	const save = (v: string) => {
		const matchingNamespace = matchNamespaceForIdentifier(v);
		editRecord({
			...getValue(),
			displayValue: {
				...getValue().displayValue,
				identifier: v,
				namespace: matchingNamespace,
			},
		});
	};

	const saveRef = useRef(save);

	saveRef.current = save;

	const { setValue, value, commit } = useBlurAndDebounceValue<string>({
		initialValue: displayValue.identifier,
		save: useCallback((v) => saveRef.current(v), [saveRef]),
	});

	return (
		<CellWrapper>
			<TableTextInput
				aria-label="Identifier Cell"
				autoFocus={displayValue.identifier === ''}
				value={value ?? ''}
				onChange={(e) => setValue(e.target.value)}
				onBlur={() => commit()}
			/>
		</CellWrapper>
	);
}
