import React from 'react';
import styled from 'styled-components';
import { AssignedTitles } from '@warehouse/object-browser/feature-show-assigned-titles';
import { GeneralInfos } from './GeneralInfos';
import { ExternalIntegrations } from './ExternalIntegrations';

export function GeneralTab() {
	return (
		<PaddingWrapper>
			<GeneralInfos />
			<AssignedTitles />
			<ExternalIntegrations />
		</PaddingWrapper>
	);
}

export const PaddingWrapper = styled.div(
	({ theme }) => `
		body {
			overflow: hidden !important;
		}
		
		display: flex;
		flex-direction: column;
	
		overflow-y: auto;
		height: calc(100vh - 128px);
		padding: ${theme.spacing(3, 2)};
		box-sizing: border-box;
	`,
);
