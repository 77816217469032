import { TitleFull as Title } from '@warehouse/title/core';
import { Inherited, OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { assertUnreachable, isInAndDefined } from '@warehouse/shared/util';
import { PlatformSpecService } from '@warehouse/global-entities/core';
import { fromBasic, getTitleData } from '../../../utils/titleGetProperty';
import { TitleCreationFormData } from '../../../../libs/title/feature-create-titles/title-creation-form-data';
import { Fields } from './useFieldsByTitleTypeName';

function getBasic(inheritedValues: Title | undefined) {
	return inheritedValues?.metadata?.coreMetadata?.basic;
}

export const validateTitlesData = (
	fields: Fields,
	titlesData: TitleCreationFormData[] | undefined,
	setTitlesErrors: (errors: { [columnId: string]: number[] }) => void,
	inheritedValues: Title | undefined,
): boolean => {
	const errors: { [columnId: string]: number[] } = {
		title: [],
		titleLanguage: [],
		countriesOfOrigin: [],
		releaseDate: [],
		licensor: [],
		mode: [],
		originalLanguages: [],
		sortTitle: [],
		approximateLength: [],
		compilationClass: [],
		sequenceNumber: [],
		distributionNumber: [],
		houseSequence: [],
		madeForRegions: [],
		editClass: [],
		editUse: [],
		versionLanguages: [],
		platformSpecificationId: [],
		alid: [],
	};

	if (!titlesData) return false;

	titlesData.forEach((titleData: TitleCreationFormData, index: number) => {
		fields.forEach(({ field, required }) => {
			switch (field) {
				case 'title':
					if (
						required &&
						!titleData.title?.length &&
						!getTitleData<OptionalInherited<string>>(
							inheritedValues,
							fromBasic(['localizedInfos', '__default', 'titleDisplayUnlimited']),
						)?.displayValue
					) {
						errors.title.push(index);
					}
					break;
				case 'countriesOfOrigin':
					if (
						required &&
						!titleData.countriesOfOrigin?.length &&
						!getBasic(inheritedValues)?.countriesOfOrigin?.displayValue?.length
					) {
						errors.countriesOfOrigin.push(index);
					}
					break;
				case 'distributionNumber':
					if (
						required &&
						!isInAndDefined(titleData, 'distributionNumber') &&
						!getBasic(inheritedValues)?.sequenceInfo?.distributionNumber?.displayValue?.distributionNumber
					) {
						errors.distributionNumber.push(index);
					}
					break;
				case 'originalLanguages':
					if (
						required &&
						!titleData.originalLanguages?.length &&
						!getBasic(inheritedValues)?.originalLanguages?.displayValue?.length
					) {
						errors.originalLanguages.push(index);
					}
					break;
				case 'releaseDate':
					if (required && !titleData.releaseDate && !getBasic(inheritedValues)?.releaseDate?.displayValue) {
						errors.releaseDate.push(index);
					}
					break;
				case 'sequenceNumber':
					if (
						required &&
						!isInAndDefined(titleData, 'sequenceNumber') &&
						getBasic(inheritedValues)?.sequenceInfo?.number?.displayValue === undefined
					) {
						errors.sequenceNumber.push(index);
					}
					break;
				case 'titleLanguage':
					if (
						required &&
						!titleData.titleLanguage &&
						!getTitleData<Inherited<string>>(inheritedValues, fromBasic(['localizedInfos', '__default', 'language']))
							?.displayValue
					) {
						errors.titleLanguage.push(index);
					}
					break;
				case 'editUse':
					if (
						required &&
						!isInAndDefined(titleData, 'editUse') &&
						!getBasic(inheritedValues)?.versionIntent?.editUse?.displayValue
					) {
						errors.editUse.push(index);
					}
					break;
				case 'editClass':
					if (
						required &&
						!isInAndDefined(titleData, 'editClass') &&
						!getBasic(inheritedValues)?.versionIntent?.editClasses?.displayValue?.length
					) {
						errors.editClass.push(index);
					}
					break;
				case 'sortTitle':
					if (
						required &&
						!titleData.sortTitle &&
						!getTitleData<OptionalInherited<string>>(
							inheritedValues,
							fromBasic(['localizedInfos', '__default', 'titleSort']),
						)?.displayValue
					) {
						errors.sortTitle.push(index);
					}
					break;
				case 'houseSequence':
					if (
						required &&
						!isInAndDefined(titleData, 'houseSequence') &&
						!getBasic(inheritedValues)?.sequenceInfo?.houseSequence?.displayValue?.houseSequence
					) {
						errors.houseSequence.push(index);
					}
					break;
				case 'madeForRegions':
					if (
						required &&
						(!('madeForRegions' in titleData) || !titleData.madeForRegions?.madeForRegions?.length) &&
						!getBasic(inheritedValues)?.versionIntent?.madeForRegions?.displayValue?.length
					) {
						errors.madeForRegions.push(index);
					}
					break;
				case 'approximateLength':
					if (required && !titleData.approximateLength && !getBasic(inheritedValues)?.runLength?.displayValue) {
						errors.approximateLength.push(index);
					}
					break;
				case 'versionLanguages':
					if (
						required &&
						(!('versionLanguages' in titleData) || !titleData.versionLanguages?.length) &&
						!getBasic(inheritedValues)?.versionLanguages?.displayValue?.length
					) {
						errors.versionLanguages.push(index);
					}
					break;
				case 'platformSpecificationId':
					if (required && !isInAndDefined(titleData, 'platformSpecificationId')) {
						errors.platformSpecificationId.push(index);
					}
					break;
				case 'alid':
					if (required && !isInAndDefined(titleData, 'alid')) {
						errors.alid.push(index);
						break;
					}
					if (isInAndDefined(titleData, 'alid')) {
						if (!titleData.platformSpecificationId) {
							errors.alid.push(index);
						} else if (
							!PlatformSpecService.isAlidValidForPlatformSpec({
								alid: titleData.alid,
								platformSpecId: titleData.platformSpecificationId,
							})
						) {
							errors.alid.push(index);
						}
					}
					break;
				default:
					assertUnreachable(field);
			}
		});
	});

	setTitlesErrors(errors);

	return Object.keys(errors).every((key) => !errors[key].length);
};
