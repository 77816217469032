import React, { useMemo } from 'react';
import styled from 'styled-components';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { FeatureId, isFeatureEnabledForCurrentTenant } from '@warehouse/shared/config';
import { useExternalIntegrations } from '../../../../src/utils/hooks/useExternalIntegrations';
import { AutoButtonGroup } from '../../../../src/components/library/AutoButtonGroup';

export function ExternalIntegrations() {
	const glimIntegrationURL = useObjectBrowserStore(objectBrowserStoreSelector.glimIntegrationURL);

	const externalIntegrations = useExternalIntegrations();

	const handleClick = async (id: string) => {
		const selectedIntegration = openWithOptions.find((option) => option.id === id);
		if (selectedIntegration) {
			const { url } = selectedIntegration;
			switch (id) {
				case 'glim':
					openInGlim(url);
					break;
				default:
					break;
			}
		}
	};

	const openInGlim = (serviceURL: string) => {
		const searchParams = new URLSearchParams();
		searchParams.append('panes', '3c');
		searchParams.append('actions', '1f');
		searchParams.append('topTabs', '00,03,02');
		searchParams.append('bottomTabs', '00,05');
		searchParams.append('track', '8');
		searchParams.append('soundfield', 'Surround');
		searchParams.append('left', '0');
		searchParams.append('right', '1');
		searchParams.append('subtitle', 'Off');
		searchParams.append('filebrowser', 'false');
		searchParams.append('path', '::');

		const fullURL = `${serviceURL}?${searchParams.toString() + glimIntegrationURL}`;

		window.open(fullURL, '_blank');
	};

	const openWithOptions = useMemo(() => {
		const options: { id: string; label: string; url: string; default?: boolean }[] = [];
		if (isFeatureEnabledForCurrentTenant('object-browser-drawer-open-in-button')) {
			externalIntegrations.forEach((integration) => {
				if (isFeatureEnabledForCurrentTenant(`object-browser-drawer-open-in-button-${integration.id}` as FeatureId)) {
					options.push({
						id: integration.id,
						label: `Open With ${integration.name}`,
						url: integration.serviceURL,
						default: false,
					});
				}
			});
		}
		return options;
	}, [externalIntegrations]);
	return (
		<Wrapper>
			{glimIntegrationURL && isFeatureEnabledForCurrentTenant('object-browser-drawer-open-in-button') && (
				<AutoButtonGroup
					nxstyle="secondary-black"
					storeKey="export-format"
					height={32}
					options={openWithOptions}
					onOptionClick={handleClick}
				/>
			)}
		</Wrapper>
	);
}
export const Wrapper = styled.div(
	({ theme }) => `
	 display: flex;
	 justify-content: flex-end;
	 padding: ${theme.spacing(1)};
	`,
);
